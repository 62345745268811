/**
 *  Toggle and slide out mobile menu
 */
const mobileNavigation = (function ($) {

  const pub = {}; // public facing functions
  const menuToggle = $('button[data-toggle="mobile-menu"]');

  pub.init = function () {
    if (menuToggle.length > 0) {
      pub._menuToggleListener();
      pub._subMenuToggleListener();
    }

    $('.placeholder-link a').on('click', function(evt) {
      evt.preventDefault();
    });
  };

  /**
   * Listen for Menu Toggle Event
   */
  pub._menuToggleListener = function () {
    const headerElement = $('.header');
    const toggleIcon = $('.navbar__toggle--icon');

    menuToggle.on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('is-active');
      headerElement.toggleClass('mobile-nav-active');
      toggleIcon.toggleClass('bi-x');
      toggleIcon.toggleClass('bi-list');
    });
  };

  /**
   * Listen for Sub Menu Toggle Event
   */
  pub._subMenuToggleListener = function () {
    const subMenuToggle = $('button[data-toggle="submenu-dropdown"]');

    subMenuToggle.on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('is-active');
      $(this).next('.dropdown__menu').toggleClass('is-active');
      $(this).attr('aria-expanded', function (i, attr) {
        return attr === 'true' ? 'false' : 'true';
      });
    });
  };

  return pub;

})(jQuery);
