/**
 *  Initialize jsModule
 *
 *  Example Javascript module with self-contained object interface.
 */
const jsModule = (function ($) {

  const pub = {}; // public facing functions

  pub.init = function () {

  };

  return pub;

})(jQuery);
