/**
 * Custom JS Scripts
 *
 * @package CW
 */

(function () {

  // Initialize Javascript modules here.
  // jsModule.init();
  mobileNavigation.init();
  contestForm.init();

})();
