/* global wp, contestVars:readonly */

/**
 *  Contest Form functionality
 */
const contestForm = (($) => {

  const pub = {}; // public facing functions

  /**
   * Wrap a "loose" email address with a mailto link.
   *
   * Based on this StackOverflow answer by Rob Wu:
   * @link https://stackoverflow.com/questions/12209628/how-to-replace-email-addresses-with-mailto-links-in-a-live-web-page#answer-12212700
   *
   * @since 2022-05-05
   *
   * @param {Element} elem The element node to add mailto link to.
   */
  function cwWrapEmail(elem) {
    var nodes = elem.childNodes,
      i = nodes.length,
      // The regexp is based on MongoEngine's EmailField pattern
      // eslint-disable-next-line max-len
      regexp = /([-!\x23$%&'*+\/=?^_`{}|~0-9A-Z]+(\.[-!\x23$%&'*+\/=?^_`{}|~0-9A-Z]+)*|^"([\x01-\x08\x0b\x0c\x0e-\x1f!\x23-\\[\\]-\x7f]|\\[\x01-011\x0b\x0c\x0e-\x7f])*")@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+[A-Z]{2,6}/i,
      node,
      emailNode,
      a,
      result;
    while (node = nodes[--i]) {
      if (node.nodeType === 1) {
        // Skip anchor tags, nested anchors make no sense
        if (node.nodeName.toUpperCase() !== 'A') {
          cwWrapEmail(node);
        }
      } else if (node.nodeType === 3) {
        // 1: Please note that the regexp has NO global flag,
        //    and that `node.textContent` shrinks when an address is found
        while (result = regexp.exec(node.textContent)) {
          // 2: Contact <SPLIT> me@example.com for details
          node = node.splitText(result.index);

          // 2: Contact <SPLIT>me@example.com<SPLIT> for details
          node = node.splitText(result[0].length);

          // me@example.com
          emailNode = node.previousSibling;

          // 3. Create link
          a = document.createElement('a');
          a.href = 'mailto:' + result[0];

          // 4: Append emailNode
          a.appendChild(emailNode);

          // 5: Insert before
          elem.insertBefore(a, node);
        }
      }
    }
  }

  /**
   * Toggle element visibility.
   *
   * @since 2022-05-03
   *
   * @param {Element} element The element to toggle.
   */
  function cwToggleVisibility(element) {
    if (element.offsetWidth > 0 && element.offsetHeight > 0) {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }

  /**
   * Toggle spinner loading.
   *
   * @since 2022-05-03
   *
   * @param {Element} spinner The spinner element to toggle.
   */
  function cwToggleLoading(spinner) {
    if (document.body.classList.contains('loading')) {
      spinner.classList.remove('loading');
      spinner.innerText = '';
    } else {
      spinner.classList.add('loading');
      spinner.innerText = wp.i18n.__('Loading', 'cw-starter');
    }
  }

  /**
   * Form Submission.
   *
   * @since 2022-05-03
   *
   * @param {SubmitEvent} submitEvent The SubmitEvent.
   * @param {Element} form The form.
   * @param {Element} contestWrapper The container of the form.
   */
  function cwContestSubmit(submitEvent, form, contestWrapper) {

    const {route, nonce, recaptchaSiteKey} = contestVars;

    if (!window.grecaptcha || !recaptchaSiteKey) {
      console.log('reCAPTCHA not set up!'); // eslint-disable-line no-console
      return;
    }

    const messages = JSON.parse(contestWrapper.dataset.messages);
    const {winHead, winSub, loseHead} = messages;
    const stockNumberInput = form.querySelector('input[name="stock_number"]');

    if (!stockNumberInput) {
      return;
    }

    const stockNumber = stockNumberInput ? stockNumberInput.value : '';

    if (!stockNumber) {
      return;
    }

    const headline = contestWrapper.querySelector('.headline');

    window.grecaptcha.ready(() => {

      const button = form.querySelector('button');
      const spinner = form.querySelector('.spinner');

      button.setAttribute('disabled', 'disabled');
      cwToggleLoading(spinner);

      window.grecaptcha
        .execute(recaptchaSiteKey, {action: 'cw_contest_form'})
        .then((recaptchaToken) => {
          fetch(
            route,
            {
              method: form.method,
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': nonce
              },
              body: JSON.stringify(
                {
                  stockNumber: stockNumber,
                  recaptchaToken: recaptchaToken,
                  nonce: nonce
                }
              )
            })
            .then((response) => {
              const status = response.status;
              response.json().then((res) => {
                button.removeAttribute('disabled');
                cwToggleLoading(spinner);
                cwToggleVisibility(form);
                if ('fail' === res.code) {
                  headline.innerText = loseHead;
                } else if ('success' === res.code) {
                  const subheading = document.createElement('p');
                  headline.innerText = wp.i18n.sprintf(winHead, res.name, res.prize);
                  subheading.classList.add('subheading');
                  subheading.innerText = winSub;
                  cwWrapEmail(subheading);
                  headline.insertAdjacentElement(
                    'afterend',
                    subheading
                  );
                } else {
                  headline.innerText = res.message;
                }
              });
            })
            .catch((error) => {
              button.removeAttribute('disabled');
              cwToggleLoading(spinner);
              cwToggleVisibility(form);
              headline.innerText = wp.i18n.__(
                'We’re sorry, an error has occurred. Please try again later.',
                'cw-starter'
              );
            });
        });
    });
  }

  /**
   * Init an individual Newsletter form.
   *
   * @since 2022-05-03
   *
   * @param {Element} contestWrapper The contest wrapper.
   */
  function cwContest(contestWrapper) {
    const form = contestWrapper.querySelector('form');

    if (!form) {
      return;
    }

    form.addEventListener('submit', (submitEvent) => {
      submitEvent.preventDefault();
      cwContestSubmit(submitEvent,form,contestWrapper);
    });
  }

  /**
   * Init the Newsletter Signup functionality.
   *
   * @since 2022-05-03
   */
  function cwContestsInit() {
    const contestWrappers = document.querySelectorAll('.contest-wrap');

    if (!contestWrappers) {
      return;
    }

    contestWrappers.forEach((contestWrapper) => {
      cwContest(contestWrapper);
    });
  }

  /**
   * Public init.
   *
   * @since 2022-05-03
   */
  pub.init = () => {
    // Make sure everything is loaded first.
    if (
      ('complete' === document.readyState || 'loading' !== document.readyState)
      && !document.documentElement.doScroll
    ) {
      cwContestsInit();
    } else {
      document.addEventListener('DOMContentLoaded', cwContestsInit);
    }
  };

  return pub;

})(jQuery);
